/* Gallery Section */
.gallery-section {
  text-align: center;
  background-color: #000;
  padding: 3rem 1rem;
  color: #fff;
  font-family: Poppins;
}

/* Titles */
.gallery-title {
  font-size: 32px;
  font-weight: 300;
  color: #fff;
  margin-bottom: -1rem;
  font-weight: normal;
}

.gallery-subtitle {
  font-size: 48px;
  background: rgba(255, 212,49 , 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Transcend;
  margin-bottom: 1rem;
}
/* Grid Layout */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
  gap: 1.5rem; /* Space between items */
  padding: 0 2rem;
}
.gallery-item {
  overflow: hidden;
  border-radius: 12px;
  height: 600px; /* Default height for square images */
}

.wide-row {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin: 1.5rem 0;
}

.gallery-item-wide {
  width: 950px;
  height: 400px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
}
.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}




.gallery-item:hover .gallery-image {
  transform: scale(1.1);
}

/* Explore Button */
.explore-gallery-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  padding: 0.75rem 1.5rem;
  background-color: #fcd34d;
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 12px;
  text-decoration: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.explore-gallery-button:hover {
  background-color: #fbbf24;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
}

.arrow {
  margin-left: 0.5rem;
  font-size: 1.25rem;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .gallery-item-wide {
    width: 800px;
    height: 350px;
  }
  
  .gallery-item {
    height: 500px;
  }
}

@media (max-width: 992px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 0 1rem;
  }

  .gallery-item-wide {
    width: 600px;
    height: 300px;
  }

  .gallery-item {
    height: 400px;
  }

  .gallery-title {
    font-size: 28px;
  }

  .gallery-subtitle {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: 1fr;
  }

  .gallery-item-wide {
    width: 100%;
    max-width: 500px;
    height: 250px;
  }

  .gallery-item {
    height: 350px;
  }

  .gallery-section {
    padding: 2rem 0.5rem;
  }

  .gallery-title {
    font-size: 24px;
  }

  .gallery-subtitle {
    font-size: 32px;
  }
}

@media (max-width: 480px) {
  .gallery-item {
    height: 250px;
  }

  .gallery-item-wide {
    height: 200px;
  }

  .explore-gallery-button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}