.footer {
  background-color: #000;
  color: #fff;
  padding: 40px 80px;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-left {
  max-width: 300px;
}

.footer-logo {
  width: 200px;
  margin-bottom: 20px;
}

.footer-left p {
  font-size: 20px;
  margin: 0;
  line-height: 1.5;
}

.footer-middle {
  display: flex;
  justify-content: space-between;
  gap: 80px;
}

.footer-column h4 {
  color: #ffc107;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  margin-bottom: 12px;
  font-size: 15px;
  color: #fff;
  transition: color 0.3s ease;
  cursor: pointer;
}

.footer-column ul li:hover {
  color: #ffc107;
}

.footer-right .social-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
}

.social-button {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 4px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s ease;
  background-color: #333;
  min-width: 100px;
  text-align: center;
}

.social-button.linkedin {
  background-color: #0077b5;
}

.social-button div{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  border-right: 1px solid rgb(63, 63, 63);
}

.social-button:hover {
  color: #ffc107;
}

.footer-bottom {
  text-align: center;
  padding-top: 20px;
  /* border-top: 1px solid #333; */
}

.footer-bottom p {
  font-size: 15px;
  margin: 0;
  color: #fff;
}

.active-link{
  font-weight: 500;
}

.active-link::before{
  content: '-';
  margin-right: 5px;
}

.footer-divider{
  width: 90%;
  height: 2px;
  background-color: rgb(67, 67, 67);
  margin: 70px auto 0;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .footer {
    padding: 40px;
  }
  
  .footer-middle {
    gap: 40px;
  }
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    gap: 40px;
  }
  
  .footer-middle {
    width: 100%;
    flex-wrap: wrap;
  }
  
  .footer-right {
    width: 100%;
  }
  
  .social-button {
    width: 100%;
  }
}