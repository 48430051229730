/* Team Member Card */
.team-member {
    text-align: center;
    width: 100%;
    max-width: 280px; 
    perspective: 1000px; /* Creates 3D effect */
    cursor: pointer;
    margin: 20px 0; /* Space between cards */
}

/* Inner container for flip animation */
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 500px;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.team-member:hover .flip-card-inner {
    transform: rotateY(180deg);
}

/* Front and Back Faces */
.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    overflow: hidden;
}

.flip-card-front {
    background-color: #000;
}

.flip-card-front img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(100%);
    transition: filter 0.3s ease;
}

.team-member:hover .flip-card-front img {
    filter: grayscale(0%);
}

.flip-card-back {
    background-color: white;
    color: #000;
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
}

/* Front Face Content */
.flip-card-front h3 {
    margin-bottom: 0;
    font-size: 18px;
    font-family: Transcend;
    color: #fff;
}

.flip-card-front p {
    margin-top: 0;
    font-weight: normal;
    font-size: 14px;
    font-family: Poppins;
    color: #ddd;
}

/* Back Face Content */
.flip-card-back h3 {
    font-family: Transcend;
    font-size: 20px;
    margin-bottom: 10px;
    color: #000;
}

.flip-card-back p {
    font-family: Poppins;
    font-size: 18px;
    margin: 5px 0;
    color: #000;
    font-weight: 800;
}
.flip-card-back .detail{
    font-size: 16px;
}
.flip-card-back .social-links {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 15px;
}

.flip-card-back .social-links a {
    color: #000;
    font-size: 20px;
    transition: transform 0.3s ease;
}

.flip-card-back .social-links a:hover {
    transform: scale(1.2);
}

/* Blur Effect for Front Face Content */
.team-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.0);
    backdrop-filter: blur(20px);
    color: #fff;
}

/* Extra Small Devices (320px and up) */
@media (min-width: 320px) {
    .team-member {
        max-width: 300px;
    }
    
    .flip-card-inner {
        height: 400px;
    }
}

/* Small Devices (375px and up) */
@media (min-width: 375px) {
    .team-member {
        max-width: 340px;
    }
    
    .flip-card-inner {
        height: 420px;
    }
}

/* Medium Devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .team-member {
        max-width: 316px;
        margin: 20px;
    }

    .flip-card-inner {
        height: 450px;
    }

    .flip-card-front h3 {
        font-size: 18px;
    }

    .flip-card-front p {
        font-size: 14px;
    }

    .flip-card-back h3 {
        font-size: 20px;
    }

    .flip-card-back p {
        font-size: 16px;
    }

    .flip-card-back .detail {
        font-size: 15px;
    }

    .flip-card-back .social-links {
        gap: 15px;
    }

    .flip-card-back .social-links a {
        font-size: 20px;
    }
}

/* Large Devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .team-member {
        max-width: 316px;
    }

    .flip-card-inner {
        height: 500px;
    }
}

/* Touch Device Optimizations */
@media (hover: none) {
    .team-member:active .flip-card-inner {
        transform: rotateY(180deg);
    }
    
    .flip-card-back .social-links a:active {
        transform: scale(1.2);
    }
}

/* Landscape Mode Handling */
@media (max-height: 500px) and (orientation: landscape) {
    .team-member {
        max-width: 280px;
    }

    .flip-card-inner {
        height: 280px;
    }

    .flip-card-back {
        padding: 10px;
    }

    .flip-card-back h3 {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .flip-card-back p {
        font-size: 13px;
        margin: 2px 0;
    }

    .flip-card-back .detail {
        font-size: 12px;
        margin: 4px 0;
    }

    .flip-card-back .social-links {
        margin-top: 8px;
    }
}