@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'Transcend';
    src: url('./Assets/transcend-regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.about-us-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    background-color: #000; /* Black background as per the uploaded image */
    color: #fff; /* White text color */
    flex-wrap: wrap;
}

.about-us-text {
    width: 60%; 
    padding: 20px; /* Add padding for spacing */
}

.about-us-text h1 {
    font-size: 60px;
    margin-bottom: 20px;
    font-weight: bold;
    background: rgba(255, 212,49 , 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    font-family: Transcend;
    
}

.about-us-text p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    font-family: 'Poppins';
    
}

.about-us-image {
    width: 30%;
    
    text-align: center;
    
}

.about-us-image img {
    width: 100%;
    height: 530px; /* Sets a fixed height for the image */
    object-fit: cover;
    border-radius: 10px; /* Optional: rounded corners */
    
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .about-us-content {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center align items */
        padding: 30px;
    }

    .about-us-text {
        width: 100%; /* Full width for mobile */
        text-align: center; /* Center-align text */
        padding: 10px; /* Adjust padding for smaller screens */
    }

    .about-us-text h1 {
        font-size: 40px; /* Reduce font size */
    }

    .about-us-text p {
        font-size: 16px; /* Slightly smaller font size */
        line-height: 1.5;
    }

    .about-us-image {
        width: 100%; /* Full width for mobile */
        text-align: center;
        padding: 10px; /* Adjust padding */
    }

    .about-us-image img {
        width: 100%; /* Full width */
        height: auto; /* Maintain aspect ratio */
    }
}

@media (max-width: 480px) {
    .about-us-content {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center-align items */
        padding: 20px; /* Adjust padding for smaller screens */
    }

    .about-us-text h1 {
        font-size: 32px; /* Further reduce font size */
    }

    .about-us-text p {
        font-size: 14px; /* Smaller font size for mobile */
        line-height: 1.4;
    }

    .about-us-image img {
        width: 100%; /* Ensure image scales properly */
        height: auto; /* Maintain aspect ratio */
    }
}
/* Numbers That Matter Section */
.numbers-that-matter {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px;
    background-color: #000; /* Black background */
    color: #fff;
    flex-wrap: wrap; /* Enable wrapping for smaller screens */
}

.numbers-image {
    width: 40%;
    text-align: center; /* Center image for smaller screens */
}

.numbers-image img {
    margin-top: 80px;
    width: 100%; /* Ensure image scales with container */
    max-width: 616px;
    height: 640px; /* Maintain aspect ratio */
    max-height: 640px;
    border-radius: 10px; /* Optional rounded corners */
}

.numbers-content {
    width: 55%;
    text-align: left;
    padding: 20px;
}

.numbers-content h2 {
    font-size: 50px;
    margin-bottom: 20px;
    font-weight: bold;
    background: rgba(255, 212,49 , 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Transcend;
}

.numbers-content p {
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 1.6;
    font-family: 'Poppins';
}

.numbers-stats {
    display: flex;
    
    flex-wrap: wrap;
    margin-top: 10px;
}

.numbers-stats div {
    text-align: center;
    margin: -15px; /* Reduced spacing between the items */
    padding-left: 60px; /* Add some padding for a cleaner look */
    width: 40%;/* Default to full width */
}

.numbers-stats h3 {
    font-size: 48px;
    font-weight: bold;
    background: rgba(255, 212,49 , 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Transcend;
    margin-bottom: 5px;
    
}

.numbers-stats p {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Poppins';
}


/* Responsive Design */
@media (max-width: 1024px) {
    .numbers-image,
    .numbers-content {
        width: 100%;
        text-align: center;
    }

    .numbers-image img {
        margin-top: 30px;
    }

    .numbers-content h2 {
        font-size: 40px;
    }

    .numbers-content p {
        font-size: 16px;
    }

    .numbers-stats h3 {
        font-size: 36px;
    }

    .numbers-stats p {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .numbers-that-matter {
        padding: 30px;
    }

    .numbers-content h2 {
        font-size: 32px;
    }

    .numbers-content p {
        font-size: 14px;
    }

    .numbers-stats h3 {
        font-size: 28px;
    }

    .numbers-stats p {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .numbers-that-matter {
        flex-direction: column;
        padding: 20px;
    }

    .numbers-image img {
        margin-top: 20px;
       
    }

    .numbers-content h2 {
        font-size: 28px;    
    }

    .numbers-content p {
        font-size: 12px;
    }

    .numbers-stats h3 {
        font-size: 24px;
    }

    .numbers-stats p {
        font-size: 16px;
    }
}

/* Renowned Names Section */
.renowned-names {
    text-align: center;
    padding: 50px 20px;
    background-color: #000; /* Black background */
    background: rgba(255, 212,49 , 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  
    font-family: Transcend;
}

.renowned-names h2 {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 20px;
    background:  rgba(225, 187, 43, 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;   /* Gold-like color */
}

.renowned-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 50px;
}

.renowned-logos img {
    max-width: 150px;
    height: auto;
    filter: brightness(0) invert(1); /* Ensures white logos on dark background */
    transition: transform 0.3s ease;
}

.renowned-logos img:hover {
    transform: scale(1.1); /* Slight zoom on hover */
}/* Crafting Unforgettable Experiences Section */
.unforgettable-experiences {
    text-align: center;
    padding: 50px 20px;
    background-color: #000; /* Black background */
    color: #fff; /* White text */
}

.unforgettable-experiences h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
    background-color: #000; 
    background: rgba(255, 212,49 , 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    font-family: Transcend;
    max-width: 900px;
    margin: 0 auto; /* Center align */
}

.unforgettable-experiences p {
    font-size: 18px;
    margin-bottom: 40px;
    line-height: 1.6;
    max-width: 800px; /* Restrict width to force two lines */
    margin: 0 auto; /* Center align */
    font-family: Poppins;
}

/* Experience Highlights Section */
.experience-highlights {
    display: flex;
    justify-content: space-evenly; /* Space out the boxes */
    gap: 5px; /* Add spacing between boxes */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    overflow-x: auto; /* Enable horizontal scrolling if needed */
    padding: 20px 0;
    margin-top: 30px;
}

.highlight {
    text-align: center;
    flex: 1; /* Equal width for all boxes */
    padding: 10px;
    background-color: #000; /* Slightly lighter black for distinction */
    border-radius: 10px;
    
    color: #fff;
    max-width: 450px; /* Set a max width for each box */
    min-width: 100px; /* Ensure a consistent width for smaller screens */
    flex-basis: calc(33.33% - 20px); /* Default width for 3 boxes per row */
}

.highlight img {
    width: 48px;
    height: 48px;
    margin-bottom: 15px;
  
}

.highlight h3 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    background: rgba(255, 212,49 , 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    font-family: Transcend;
}

.highlight p {
    font-size: 19px;
    line-height: 1.4;
    font-family: Poppins;
}


/* Responsive Adjustments */
@media (max-width: 992px) {
    .highlight {
        flex-basis: calc(50% - 20px); /* 2 boxes per row for medium screens */
    }
}

@media (max-width: 600px) {
    .highlight {
        flex-basis: calc(100% - 20px); /* 1 box per row for small screens */
    }
}
/* Dream Team Section */
.dream-team {
    text-align: center;
    padding: 30px 200px;
    background-color: #000; /* Black background */
    color: #fff; /* White text */
   
}

.dream-team h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
    background: rgba(255, 212,49 , 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    font-family: Transcend;
}

.dream-team p {
    font-size: 18px;
    margin-bottom: 40px;
    line-height: 1.6;
    max-width: 700px;
    margin: 10px auto; /* Center align text */
    font-family: Poppins;
}

.team-members {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 4 columns per row */
    gap: 10px; /* Add space between team member cards */
    justify-items: center; /* Center align the grid items */
    margin-bottom: 60px;
    margin-top: 50px;
    margin-left: 25%;
}
.team-members {
    padding: 0 50px; /* Add spacing from the left and right edges */
}
/* Small tablets and large phones (480px and up) */
@media (min-width: 332px) {
    .dream-team {
        padding: 25px 20px;
       
    }

    .dream-team h2 {
        font-size: 26px;
    }

    .team-members {
        grid-template-columns: repeat(1, 1fr); /* 2 columns */
        gap: 15px;
        padding: 0 20px;
    }
}

/* Small tablets and large phones (480px and up) */
@media (min-width: 480px) {
    .dream-team {
        padding: 25px 20px;
    }

    .dream-team h2 {
        font-size: 36px;
    }

    .team-members {
        grid-template-columns: repeat(2, 1fr); /* 2 columns */
        gap: 15px;
        padding: 0 20px;
    }
}

/* Medium tablets (768px and up) */
@media (min-width: 768px) {
    .dream-team {
        padding: 30px 40px;
    }

    .dream-team h2 {
        font-size: 42px;
    }

    .dream-team p {
        font-size: 17px;
        max-width: 600px;
    }

    .team-members {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        padding: 0 30px;
        max-width: 900px;
        margin: 40px auto;
    }
}

/* Large tablets and small desktops (992px and up) */
@media (min-width: 992px) {
    .dream-team {
        padding: 30px 60px;
    }

    .dream-team h2 {
        font-size: 48px;
    }

    .dream-team p {
        font-size: 18px;
        max-width: 700px;
    }

    .team-members {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        padding: 0 50px;
        margin-left: 25%;
    }
}

/* Large desktops (1200px and up) */
@media (min-width: 1200px) {
    .dream-team {
        padding: 30px 200px;
    }

    .team-members {
        gap: 30px;
        max-width: 1200px;
    }
}

/* Handle very small screens */
@media (max-width: 320px) {
    .dream-team h2 {
        font-size: 28px;
    }

    .dream-team p {
        font-size: 14px;
    }

    .team-members {
        padding: 0 10px;
    }
}

/* Handle landscape orientation */
@media (max-height: 500px) and (orientation: landscape) {
    .dream-team {
        padding: 15px;
    }

    .dream-team h2 {
        font-size: 28px;
        margin-bottom: 10px;
    }

    .dream-team p {
        font-size: 14px;
        margin: 5px auto;
    }

    .team-members {
        margin: 20px auto;
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.about-us-image, .numbers-image {
    opacity: 0; /* Start hidden */
}

.slide-in-right {
    animation: slideInFromRight 1s ease forwards;
}

.slide-in-left {
    animation: slideInFromLeft 1s ease forwards;
}