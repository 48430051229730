/* Testimonials Section */
.testimonials-section {
    text-align: center;
    background-color: #000;
    color: #fff;
    padding: 3rem 1.5rem;
    font-family: 'Georgia', serif;
  }
  
  /* Title and Subtitle */
  .testimonials-title {
    font-size: 48px;
    background: rgba(255, 212,49 , 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    font-family: Transcend;
    margin-bottom: 0.5rem;
  }
  
  .testimonials-subtitle {
    font-size: 18px;
    color: #ddd;
    margin-bottom: 2rem;
    font-family: 'Poppins';
     
  }
  

  
  /* Individual Testimonial Items */
  .testimonial-item {
    position: absolute;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 1s ease, transform 1s ease;
  text-align: center;
  max-width: 90%; /* Adjusted for better mobile fit */
  margin: 0 auto;
  padding: 1.5rem;
  }
  
  .testimonial-item:hover {
    transform: translateY(-10px); /* Subtle lift on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  }
  
  /* Quote */
  .testimonial-quote {
    font-size: 20px;
    font-style: italic;
    color: #ccc;
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
  
  /* Images */
  .testimonial-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
    border: 2px solid #ffd700; /* Golden border around images */
  }
  
  /* Names and Titles */
  .testimonial-name {
    font-size: 18px;
    font-weight: bold;
    color: #ffd700;
    margin-bottom: 0.2rem;
  }
  
  .testimonial-title {
    font-size: 0.9rem;
    color: #aaa;
  }
  /* Slider Container */
.testimonial-slider {
  position: relative;
  overflow: hidden;
  height: 500px; /* Adjust as needed for content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Testimonial Items */
.testimonial-item {
  position: absolute;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 1s ease, transform 1s ease;
  text-align: center;
  max-width: 600px; /* Limit the width of testimonials */
}

.testimonial-item.active {
  opacity: 1;
  transform: translateX(0);
}

.testimonial-item.inactive {
  opacity: 0;
  transform: translateX(-100%);
}

@media (max-width: 480px) {
  .testimonials-title {
    font-size: 24px; /* Smaller title for mobile */
  }

  .testimonials-subtitle {
    font-size: 12px; /* Smaller subtitle for mobile */
  }

  .testimonial-item {
    padding: 1rem; /* Reduced padding for mobile */
  }

  .testimonial-quote {
    font-size: 14px; /* Smaller font size for quotes */
  }

  .testimonial-image {
    width: 40px; /* Smaller image size for mobile */
    height: 40px;
  }

  .testimonial-name {
    font-size: 12px; /* Smaller font for names */
  }

  .testimonial-title {
    font-size: 0.7rem; /* Smaller font for titles */
  }
}