.featured-events {
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem;
  padding-left: 7rem;
  margin-right: 50px;
  gap: 1rem;
  flex-wrap: nowrap; 
}

.featured-text {
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    gap: 1rem; /* Add spacing between elements */
  }
.featured-title {
    font-size: 48px;
    font-weight: bold;
    background: rgba(255, 212,49 , 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    font-family: Transcend;
    
    margin-bottom: 1rem;
   
}

.featured-description {
    font-size: 18px;
    margin-bottom: 2rem;
    line-height: 1.5; /* Maintain spacing between lines */
    width: 60%; /* Adjust the width to restrict text flow and create space on the right */
    text-align: left; /* Ensure the text stays aligned to the left */
    white-space: normal; /* Allow text to wrap */
    overflow: hidden; /* Prevent overflow of extra text */
    display: -webkit-box; /* Enable multiline text truncation */
    -webkit-line-clamp: 3.5; /* Clamp the text to exactly 3 lines */
    -webkit-box-orient: vertical; /* Ensure proper text orientation */
   
}
.explore-events {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.1rem;
    padding: 0.75rem 1.5rem;
    background-color: #FBD88D; /* Light yellow background */
    color: #000; /* Black text color */
    font-size: 1rem;
    font-weight: bold;
    border-radius: 12px; /* Rounded corners */
    text-decoration: none;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .explore-events:hover {
   
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15); /* Deeper shadow */
  }
  
  .arrow {
    font-size: 1.5rem;
    transition: transform 0.3s ease; /* Smooth animation for arrow */

  }
 

.featured-event-cards {
  display: flex;
  flex: 1.5;
  gap: 1rem;
  justify-content: flex-start; /* Keep cards aligned to the left */
  align-items: center;
  flex-wrap: nowrap;
  
}

.featured-card {
  width: 101.33px;
  height: 389px;
  background-size: cover;
  background-position: center;
  border-radius: 50px; /* Default radius */
  transition: all 0.5s ease; /* Smooth transition for width, height, and radius */
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.featured-card h2, .featured-card-large h2 {
  margin-bottom: 0;
  transition: all 0.3s ease;
  animation: appear 0.5s ease;
}

.featured-card p, .featured-card-large p {
  margin-top: 0;
  font-size: 13px;
  width: 70%;
  margin-bottom: 30px;
  transition: all 0.3s ease;
  animation: appear 0.5s ease;  
}

.featured-card:hover {
  width: 340px; /* Increases width on hover */
  height: 379px;
  border-radius: 20px; /* Adjusts radius to maintain oval shape */
  transform: scale(1.05);
  box-shadow: 0 500px 0 0 inset rgba(0, 0, 0, 0.55); /* Slight scaling effect */
}

.featured-card-large{
  
    background-size: cover;
    background-position: center;
  
    transition: all 0.3s ease;
    cursor: pointer;
    text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  box-shadow: 0 500px 0 0 inset rgba(0, 0, 0, 0.55);
}

.event-title {
  font-size: 1.25rem;
  margin: 0.5rem 0;
}

.event-date {
  font-size: 1rem;
  color: #aaa;
}
/* Cards Container */
.featured-event-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default: 3 cards per row */
  gap: 1rem;
}

@keyframes appear{
  0%{
    opacity: 0;
  }
  40%{
    opacity: 0;
  }
  60%{
    opacity: 1;
  }
  100%{
    opacity: 1;
  }
}

/* Responsive Styles */



/* Medium Screens (Tablets) */
@media (max-width: 768px) {
  .featured-events {
    flex-direction: column; /* Stack text and cards vertically */
  }

  .featured-text {
    text-align: center;
    margin-bottom: 2rem;
  }

  .featured-event-cards {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
    gap: 1.5rem;
  }

  .featured-card {
    height: 250px;
  }

  .featured-title {
    font-size: 36px;
  }

  .featured-description {
    font-size: 16px;
  }
}
/* Small Screens (Mobile) */
@media (max-width: 480px) {
  .featured-events {
    flex-direction: column; /* Stack text and cards vertically */
  }



  .featured-event-cards {
    grid-template-columns: repeat(1, 1fr); /* 2 cards per row */
    gap: 0.8rem; /* Slightly reduce gap between cards */
    transform: translateX(-60px); /* Shift cards slightly left */
  
  }

  .featured-card {
    height: 150px; /* Reduce card height */
    width: 100%; /* Ensure cards fill the grid column width */
    font-size: 12px; /* Adjust font size for smaller cards */
  }

  .featured-card h2 {
    font-size: 14px; /* Smaller font size for headings */
  }

  .featured-card p {
    font-size: 12px; /* Smaller font size for descriptions */
    margin-bottom: 0.8rem; /* Reduce bottom margin */
  }

  .featured-title {
    font-size: 24px; /* Adjust title size */
  }

  .featured-description {
    font-size: 12px; /* Smaller text for description */
    text-align: left;
    
  }
}
